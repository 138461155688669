import React from 'react'
import { Chip, Stack } from '@mui/material'

// These are synchronized with:
// https://github.com/Mesh-Digital-Services/mesh-vow-configs/blob/main/labels/mesh/mesh-labels.md

// 'chipWarning', 'chipNegative', 'chipPositive',
const LABEL_COLORS = {
  'business_address_mismatch_major': 'chipNegative',
  'business_address_mismatch_minor': 'chipWarning',
  'business_name_mismatch_major': 'chipNegative',
  'business_name_mismatch_minor': 'chipWarning',
  'business_not_found': 'chipNegative',
  'business_verified': 'chipPositive',
  'document_esign_completed': 'chipPositive',
  'document_esign_declined': 'chipNegative',
  'document_not_valid': 'chipNegative',
  'coverage_insufficient': 'chipNegative',
  'coverage_verified': 'chipPositive',
  'coverage_verified_with_comments': 'chipWarning',
  'policy_cancelled': 'chipNegative',
  'policy_expired': 'chipNegative',
  'policy_expiring': 'chipWarning',
  'policy_not_carried': 'chipWarning',
  'waiver_requested': 'chipWarning',
  'tin_matched': 'chipPositive',
  'tin_not_issued': 'chipNegative',
  'tin_not_matched': 'chipNegative',
  'w9_not_valid': 'chipNegative',
  'w9_verified': 'chipPositive',
  'plaid_ach_not_valid': 'chipNegative',
  'plaid_ach_verified': 'chipPositive',
  'license_number_mismatch_major': 'chipNegative',
  'license_number_mismatch_minor': 'chipWarning',
  'license_type_mismatch': 'chipNegative',
  'name_mismatch_major': 'chipNegative',
  'name_mismatch_minor': 'chipWarning',
  'professional_license_inactive': 'chipNegative',
  'professional_license_not_found': 'chipNegative',
  'professional_license_verified': 'chipPositive',
  'verified': 'chipPositive',
  'wc_waiver_completed': 'chipPositive',
  'wc_waiver_declined': 'chipNegative',
  'approved': 'chipPositive',
  'approved_with_comments': 'chipWarning',
  'rejected': 'chipNegative',
  'supplemental_workflow': 'chipWarning',
  'mesh_inspection': 'chipWarning',
  'mobile_number_verified': 'chipPositive',
  'no_professional_license': 'chipWarning',
  'business_issue': 'chipNegative',
  'google_web_search_presence_found': 'chipPositive',
  'individual_business_association_found': 'chipPositive',
  'google_presence_found': 'chipPositive',
  'business_social_network_presence_found': 'chipPositive',
  'google_web_search_presence_not_found': 'chipWarning',
  'individual_business_association_not_found': 'chipWarning',
  'google_presence_not_found': 'chipWarning',
  'business_social_network_presence_not_found': 'chipWarning',
}

export const LabelStack = ({ labels }) => {
  if (!labels) {
    return null
  }
  return (
    <Stack direction='row' spacing='0.25rem'>
      {labels.map((label) => {
        const color = LABEL_COLORS[label]
        return <Chip 
          key={`chip-${label}`}
          size='small'
          label={label}
          {...(color ? { color } : {})} />
      },
      )}
    </Stack>
  )
}

export default LabelStack