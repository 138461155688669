import './App.css';
import { Authenticator } from "@aws-amplify/ui-react";
import Amplify from 'aws-amplify';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from '../src/Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound'
import Users from './Pages/Users/Users';
import InviteUser from './Pages/InviteUser';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup'
import RequireAuth from './Components/RequireAuth/RequireAuth';

import Sidebar from './Layout/Sidebar';

import { ApiKeyContextProvider } from './services/apiKeyService';

import awsconfig from './aws-exports';
import { WhoAmIContextProvider } from './services/whoami';
import { UserContextProvider } from './services/userService'; 
import ApiKeys from './Pages/ApiKeys/ApiKeys'
import { WorkflowVerificationContextProvider } from './services/workflowVerificationService';

import { MeshThemeProvider } from './shared/themes';
import WorkflowVerification from './Pages/WorkflowVerification';

import { CustomerDocsProvider } from './services/customerDocsService';
import CustomerDocs from './Pages/customerDocs/customerDocs';

Amplify.configure(awsconfig);


// Use a compose to allow stacking multiple providers instead of nesting them
const Compose = ({ items, children }) => {
  return items.reduceRight(
    (acc, Provider) => <Provider>{acc}</Provider>,
    children,
  );
}

function App() {
  return (
    <Compose items={[
      Authenticator.Provider,
      UserContextProvider,
      WhoAmIContextProvider,
      ApiKeyContextProvider,
      WorkflowVerificationContextProvider,
      MeshThemeProvider,
      CustomerDocsProvider,
    ]}>
      <BrowserRouter>
        <div className='App'>
          <Routes>
            {/** Anything under this path will require auth */}
            <Route path="/" element={<RequireAuth><Sidebar /></RequireAuth>}>
              <Route index path="/" element={<Navigate to="/orders" replace={true} />}/>
              <Route index path="dashboard" element={<Home />}/>
              <Route path="settings/users" element={<Users />}/>
              <Route path="settings/users/invite" element={<InviteUser />}/>
              <Route path="settings/apiKeys" element={<ApiKeys />}/>
              <Route path='orders'>
                <Route index element={<WorkflowVerification/>}/>
                <Route path=':orderId' element={<WorkflowVerification/>}/>
              </Route>
              <Route index path="customerDocs/*" element={<CustomerDocs />}/>
              {/* <Route path='credentials'>
                <Route index element={<Credentials/>}/>
                <Route path=':verificationId' element={<Credentials/>}/>
              </Route> */}
            </Route>
            <Route path="/login" element={<Login />}/>
            <Route path="/confirmRegistration" element={<Signup />}/>
            
            
            {/* This route is "public" and does not require authentication. Free Cookies can be setup like this */}
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/download/:fileType/:fileId" element={<RequireAuth><Download /></RequireAuth>} /> */}

          </Routes>
        </div>
      </BrowserRouter>
    </Compose>
  );
}

export default App;

