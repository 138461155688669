
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_FeqmUo1YY",
  "aws_user_pools_web_client_id": "3gngs4stvsulfiroviomcbm1t9",
  "oauth": {},
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_appsync_graphqlEndpoint": "https://k74adyff2jbvpiu7pylxo5cu4u.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_download_endpoint": "https://o8ald64qg8.execute-api.us-east-1.amazonaws.com",
  "sentry_dsn": "https://071016ba2e584cf8b42ff0660297d2cf@o420827.ingest.sentry.io/6526055",
  "stage": "prod"
};
export default awsmobile;
