import React, { useState, useContext, useEffect } from 'react';
import { Box, List, Chip, Stack, Typography, Divider, CircularProgress, Grid, Button, Link } from '@mui/material';
import { WorkflowVerificationContext } from '../../services/workflowVerificationService';
import LabelStack from '../../Components/LabelStack';


const FILE_VSKUS = [  'v-w9-e-sign@1.0', 'v-w9-e-sign-sandbox@1.0']

// TODO: Replace these with configuration data from the backend
const VSKU_NAMES = {
  'v-pro-license@1.0': 'Professional License',
  'v-pro-license-sandbox@1.0': 'Professional License',
  'v-bus@1.0': 'Business Verification',
  'v-bus-tin@1.0': 'Business Verification',
  'v-bus-info-wizard-sandbox@1.0': 'Business Verification',
  'v-bus-info-wizard@1.0': 'Business Verification',
  'v-bus-info-risk-wiz-sandbox@1.0': 'Business Verification',
  'v-bus-info-risk-wizard@1.0': 'Business Verification',
  'v-business-contact@1.0': 'Business Contact',
  'v-bus-w-tin@1.0': 'Business Verification',
  'v-bus-w-tin-sandbox@1.0': 'Business Verification',
  'v-proof-of-business@1.0': 'Proof of Business',
  'v-proof-of-business-sandbox@1.0': 'Proof of Business',
  'v-w9-document@1.0': 'W-9 Document',
  'v-w9-document-sandbox@1.0': 'W-9 Document',
  'v-w9-e-sign@1.0': 'W-9 E-Sign',
  'v-w9-e-sign-sandbox@1.0': 'W-9 E-Sign',
  'v-insurance-sandbox@1.0': 'Insurance',
  'v-insurance@1.0': 'Insurance',
  'v-mobile-verification@1.0': 'Mobile Number',
  'v-personal-w-ssn@1.0': 'Submitter Information',
  'v-personal-w-ssn-sandbox@1.0': 'Submitter Information',
  'v-personal-w-occ@1.0': 'Submitter Information Occupation',
  'v-personal-w-occ-sandbox@1.0': 'Submitter Information Occupation',
  'v-bus-pre-google-pl@1.0': 'Business Presence Google Places',
  'v-bus-pre-google-pl-sandbox@1.0': 'Business Presence Google Places',
  'v-bus-pre-google-web-search@1.0': 'Business Presence Google Web Search',
  'v-bus-pre-google-web-search-sandbox@1.0': 'Business Presence Google Web Search',
  'v-bus-pres-pr-soc-n@1.0': 'Business Presence Prof Social Network',
  'v-bus-pres-pr-soc-n-sandbox@1.0': 'Business Presence Prof Social Network',
  'v-bus-risk-nl-wiz@1.0': 'Business Risk Wizard Without Liens',
  'v-bus-risk-nl-wiz-sandbox@1.0': 'Business Risk Wizard Without Liens',
}
const CLAIM_NAMES = {
  'licenseeName': 'Licensee Name',
  'licenseIdentifier': 'License Number',
  'licenseStatus': 'License Status',
  'licenseStatusAsIs': 'License Status As Is',
  'licenseType': 'License Type',
  'licenseCategory': 'License Category',
  'licenseAuthorityBoard': 'License Authority Board',
  'licenseSubtype': 'License Subtype',
  'licenseTypeAsIs': 'License Type As Is',
  'licenseIssuedDate': 'License Issue Date',
  'licenseExpirationDate': 'License Expiration Date',
  'jurisdiction': 'Issuing Authority',
  'businessWebsiteUrl': 'Business Website',
  'taxIdentificationNumber': 'Taxpayer Identification Number (TIN)',
  'businessAddress': 'Business Address',
  'businessName': 'Business Name',
  'fileName': 'File Name',
  'insuranceAgentEmail': 'Email Address',
  'insuranceBeneficiaryFirstName': 'First Name',
  'insuranceBeneficiaryLastName': 'Last Name',
  'insuranceBeneficiaryEmail': 'Business Email',
  'insuranceRegisteredBusinessName': 'Business Name',
  'insuranceRegisteredBusinessAddress': 'Business Address',
  'insuranceTaxIdentificationNumber': 'Taxpayer Identification Number (TIN)',
  'insuranceRequirementsTemplate': 'Requirements Template',
  'insuranceRequestorId': 'Requestor ID',
  'insurancePolicies': 'Policies',
  'status': 'Status',
  'statusAsIs': 'Status As Is',
  'registrationDate': 'Registration Date',
  'liens': 'Liens',
  'litigations': 'Litigations',
  'bankruptcies': 'Bankruptcies',
  'consentToMobileNumberVerification': 'Mobile Number Verified',
  'mobileNumber': 'Mobile Number',
  'firstName': 'First Name',
  'lastName': 'Last Name',
  'dateOfBirth': 'Date of Birth',
  'lastFourSsn': 'Last 4 of SSN',
  'occupation': 'Occupation',
  'citizenshipUS': 'US Citizen',
  'disregardedEntityName': 'Disregarded Entity Name',
  'businessType': 'Business Type',
  'taxIdentificationNumberType': 'Taxpayer Identification Number Type',
  'businessPhone': 'Business Phone',
  'businessWebsite': 'Business Website',
  'businessMailingAddress': 'Mailing Address',
  'businessId': 'Business ID',
  'individualName': 'Individual Name',
  'individualEmailAddress': 'Individual Email Address',
  'individualConnectionsCount': 'Individual Connections Count',
  'individualProfileFirstDetectedTimestamp': 'Individual Profile First Detected Timestamp',
  'businessProfileFirstDetectedTimestamp': 'Business Profile First Detected Timestamp',
  'placeId': 'Place ID',
  'reviewCount': 'Review Count',
  'rating': 'Rating',
  'businessStatus': 'Business Status',
  'webPresenceTitle': 'Web Presence Title',
  'webPresenceDescription': 'Web Presence Description',
  'webPresenceUrl': 'Web Presence URL',
  'webPresenceFoundTimestamp': 'Web Presence Found Timestamp',
}

const CLAIM_TYPE = {
  licenseExpirationDate: 'date',
  licenseIssuedDate: 'date',
  registrationDate: 'date',
  webPresenceFoundTimestamp: 'date',
}

const formatValue = ({ name, value }) => {
  if(CLAIM_TYPE[name] === 'date') {
    if (!value) {
      return '-'
    }
    const date = new Date(parseInt(value, 10))
    return date.toLocaleDateString()
  }
  return value
}

const getFields = ({ claims, results }) => {
  if (results && results.length > 0) {
    return results
  }
  if (claims && claims.length > 0) {
    return claims
  }
  return []
}

const VerificationOrderItem = ({ item, index }) => {
  const { vsku, labels } = item
  const fields = getFields(item)
  if (fields.some(({ name }) => name === 'documentId') && FILE_VSKUS.includes(vsku)) {
    const fieldMap = Object.fromEntries(fields.map(({ name, value }) => ([name, value])))
    return (
      <Box key={`order-item-${index}`}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='h5'>{VSKU_NAMES[vsku] || vsku}</Typography> 
          <LabelStack labels={labels} />
        </Stack>
        <Grid container sx={{ backgroundColor: 'rgba(245, 245, 245, 0.60)', padding: '1rem', textAlign: 'left' }}>
          <React.Fragment key={`order-item-1`}>
            <Grid item xs={6}>
              <Typography textAlign='left' variant='subtitle1'>{CLAIM_NAMES['fileName']}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Link href={fieldMap['documentId']} textAlign='left'>
                {fieldMap['fileName']}
              </Link>
            </Grid>
          </React.Fragment>
        </Grid>
      </Box>
    )
  }

  return (
    <Box key={`order-item-${index}`}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h5'>{VSKU_NAMES[vsku] || vsku}</Typography> 
        <LabelStack labels={labels} />
      </Stack>
      
      <Grid container sx={{ backgroundColor: 'rgba(245, 245, 245, 0.60)', padding: '1rem', textAlign: 'left' }}>
        {fields.map((result, idx) => 
        <React.Fragment key={`order-item-${idx}`}>
          <Grid item xs={6}>
            <Typography textAlign='left' variant='subtitle1'>{CLAIM_NAMES[result.name] || result.name}</Typography>
          </Grid>
          <Grid item xs={6}>
          <Typography textAlign='left'>{formatValue(result)}</Typography>
          </Grid>
        </React.Fragment>,
        )}
      </Grid>
    </Box>
    
  )
}

export const VerificationSidePanel = ({ orderId }) => {
  const { getWorkflowVerification } = useContext(WorkflowVerificationContext)
  const [orderDetails, setOrderDetails] = useState()
  useEffect(async () => {
    if (orderId) {
      const details = await getWorkflowVerification({ orderId })
      console.log('got details', details)
      setOrderDetails(details)
    }
  }, [getWorkflowVerification, orderId])
  if (!orderDetails) {
    return (
      <Box sx={{ padding: '2rem', height: '100%', minWidth: '40vw' }}>
        <CircularProgress />
      </Box>
    )
  }

  const { title, businessId, labels, jurisdictions, items, orderStatus } = orderDetails || {}
  const orderAttributes = [
    { label: 'Order ID:', value: orderId },
    { label: 'Business ID:', value: businessId },
    { label: 'Status:', value: orderStatus },
  ].filter(({ value }) => value)
  return (
    <Box sx={{ padding: '2rem', height: '100%', width: '40vw' }}>
        <Stack direction='column' justifyContent='space-between' height='100%'>
        <Box>
          <Stack direction='row' sx={{ alignItems: 'end' }} spacing='0.25rem'>
            <Typography variant='h5' color='emphasis.main'>{title}</Typography>
            <Typography variant='h6' color='emphasis.light' lineHeight='1.5'>{['', ...jurisdictions].join(' / ')}</Typography>
          </Stack>
          <Box sx={{ backgroundColor: 'rgba(245, 245, 245, 0.60)' }} marginY='1rem' padding='1rem' >
            {orderAttributes.map(({ label, value }, index) => 
              <Stack key={`attribute-${index}`} direction='row' justifyContent='space-between' spacing='0.25rem'>
                <Typography variant='subtitle2'>{label}</Typography>
                <Typography variant='subtitle2'>{value}</Typography>
              </Stack>,
            )}
          </Box>
          <LabelStack labels={labels} />
          <Divider sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} />
          <List>
            {items.map((item, index) => <VerificationOrderItem item={item} index={index} />)}
          </List>
          
        </Box>
        {/* Disable this button until we have this functionality
        <Box>
          <Button variant='outlined'>Cancel Order</Button>
        </Box> */}
      </Stack>
        
      
    </Box>
    
  )
}