import { createContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { getCustomerDocs as getCustomerDocsQuery } from '../graphql/queries'

const CustomerDocsContext = createContext()
export default CustomerDocsContext

const ContextState = () => {
  const getDocumentation = async (docPath) => {
    const response = await API.graphql(graphqlOperation(getCustomerDocsQuery, {
      docPath,
    }))
    return response.data.getDocumentation
  }

  return {
    getDocumentation,
  }
}

const CustomerDocsProvider = ({ children }) => {
  const state = ContextState()

  return (
    <CustomerDocsContext.Provider value={state}>
      {children}
    </CustomerDocsContext.Provider>
  )
}

export { CustomerDocsContext, CustomerDocsProvider }
